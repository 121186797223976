.card-container {
  border-radius: 5px;
  position: relative;
}

.card-container:hover {
  cursor: pointer;
}

.card-container:hover .card-bottom {
  background-color: rgb(0, 0, 0);
}

.card-content-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #eeebdd;
  transition: background-color 0.3s ease;
}

.card-content-wrapper:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.card-bottom {
  width: 100%;
  transition: background-color 0.5s ease;
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.1) 10%,
    rgba(0, 0, 0, 0.3) 20%,
    rgba(0, 0, 0, 0.45) 30%,
    rgba(0, 0, 0, 0.5) 40%,
    rgba(0, 0, 0, 0.55) 50%,
    rgba(0, 0, 0, 0.6) 60%,
    rgba(0, 0, 0, 0.7) 70%,
    rgba(0, 0, 0, 0.8) 80%,
    rgba(0, 0, 0, 0.9) 90%,
    rgba(0, 0, 0, 1) 100%
  );
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.card-content-wrapper .card-bottom h3 {
  text-align: center;
  padding: 0.5rem;
  text-decoration: none;
}

.card-rank {
  background-color: #1b1717;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: bold;
}

.card-top {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0.5rem;
}

@media all and (max-width: 400px) {
  .card-container {
    margin: auto;
  }
}
