.advanced-search-results-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 80%;
  margin: auto;
  position: relative;
}

.search-message {
  color: #eeebdd;
  font-size: 15px;
  text-align: center;
}
