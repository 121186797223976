.secondary-filter {
  width: 80%;
  margin: 40px auto 20px;
  display: flex;
  justify-content: flex-end;
}

.secondary-filter .sort-by {
  position: relative;
}

