.banner {
  width: 100%;
  height: 400px;
}

@media screen and (max-width: 850px) {
  .banner {
    height: 300px;
  }
}

@media screen and (max-width: 550px) {
  .banner {
    height: 200px;
  }
}
