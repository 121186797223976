.list-item {
  font-size: 1.5rem;
  color: #eeebdd;
  display: grid;
  align-items: center;
  justify-items: center;
  border-bottom: 2px solid #ce1212;
  padding: 1rem 0;
  text-align: center;
  position: relative;
}

.nine-grid {
  grid-template-columns: 0.5fr 0.5fr 2fr repeat(6, 1fr);
}

.eight-grid {
  grid-template-columns: 0.5fr 0.5fr 2fr repeat(5, 1fr);
}

.mylist-item-title {
  justify-self: left;
  text-align: left;
  padding-left: 10px;
}

.mylist-item-img-container img {
  width: 50px;
  height: 70px;
}

.editable:hover {
  cursor: pointer;
}

.mylist-item-episodes input {
  width: 40px;
}

.remove-button {
  background-color: #ce1212;
  color: #1b1717;
  padding: 0.5rem 1rem;
  border: 2px solid #1b1717;
  font-weight: bold;
  box-shadow: 1px 1px 1px #ce1212;
  transition: all 0.1s linear;
  border-radius: 5px;
}

.remove-button:active {
  box-shadow: 1px 1px 1px #000 inset;
}

.remove-button:hover {
  cursor: pointer;
}

.mylist-item-comment {
  position: relative;
}

.comment-wrapper {
  position: absolute;
  right: calc(100% + 5px);
  top: 0;
  min-width: 500px;
  z-index: 1;
  display: none;
  justify-content: flex-end;
  max-height: 300px;
}

.comment-container {
  color: black;
  background-color: #eeebdd;
  padding: 10px;
  border-radius: 5px;
  width: fit-content;
  text-align: left;
  white-space: pre-line;
  word-break: break-word;
  overflow: hidden;
  overflow-y: scroll;
}

.comment-container::-webkit-scrollbar {
  width: 5px;
}

.comment-container::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}

.comment-container::-webkit-scrollbar-thumb {
  background: #aaa;
  border-radius: 10px;
}

.comment-container::-webkit-scrollbar-thumb:hover {
  background: #666;
}

.comment-edit-window {
  position: fixed;
  padding: 10px;
  left: calc(50% - 260px);
  top: calc(50% - 175px);
  z-index: 2;
  background-color: #eeebdd;
  color: #1b1717;
  border-radius: 5px;
}

.comment-edit-window h3 {
  margin-bottom: 10px;
}

.comment-edit-window textarea {
  width: 500px;
  height: 300px;
  background-color: #eeebdd;
  border-radius: 5px;
  padding: 10px;
  font-family: "Questrial", sans-serif;
  font-size: 15px;
}
