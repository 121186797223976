.hero {
  background: url("./hero_image_aot.jpg") no-repeat center center/cover;
  height: 65vh;
}

.lists-container {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 30px auto 0;
  gap: 50px;
  position: relative;
}
