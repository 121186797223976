.va-role-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #222;
  border-radius: 5px;
}

.va-role-card img {
  border-radius: 5px;
}

.anime-role,
.character-role {
  display: flex;
  gap: 10px;
}

.basic-info {
  display: flex;
  flex-direction: column;
  color: #eeebdd;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: space-between;
  align-items: flex-end;
  text-align: right;
}

.anime-role {
  font-size: 1.3rem;
  font-weight: bold;
  color: #eeebdd;
}

.anime-role:hover p,
.character-role:hover .name {
  text-shadow: -0.5px -0.5px 0 #ce1212, 0.5px -0.5px 0 #ce1212,
    -0.5px 0.5px 0 #ce1212, 0.5px 0.5px 0 #ce1212;
}
