.character-actor-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #222;
}

.block {
  display: flex;
  gap: 10px;
}

.some-info {
  display: flex;
  flex-direction: column;
  color: #eeebdd;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
}

.actor .some-info {
  align-items: flex-end;
  text-align: right;
}

.name {
  font-size: 1.3rem;
  font-weight: bold;
}

.role,
.language {
  font-size: 1rem;
  color: #a1a1a1;
}

.block img {
  width: 70px;
  min-width: 70px;
  height: 90px;
}

.character:hover .name,
.actor:hover .name {
  text-shadow: -0.5px -0.5px 0 #ce1212, 0.5px -0.5px 0 #ce1212,
    -0.5px 0.5px 0 #ce1212, 0.5px 0.5px 0 #ce1212;
}
