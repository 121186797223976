.search-menu {
  display: flex;
  font-size: 15px;
  justify-content: center;
  margin-top: 50px;
  gap: 20px;
}

.menu-item {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.menu-item-name {
  color: #eeebdd;
  font-weight: bold;
  font-size: 18px;
}

.search-bar {
  position: relative;
}
.search-bar .placeholder {
  position: absolute;
  color: #1b1717;
  z-index: 1;
  width: 15px;
  height: 15px;
  top: calc(50% - 7.5px);
  left: 10px;
}

.search-bar input {
  padding-left: 10px;
}

.filter {
  height: 35px;
  border: none;
  outline: none;
  border-radius: 5px;
  width: 170px;
  background-color: #eeebdd;
  font-size: 1.5rem;
  position: relative;
  color: #1b1717;
}

.dropdown {
  position: relative;
}

.dropdown:hover {
  cursor: pointer;
}

.filter .placeholder {
  color: #1b1717;
  font-weight: 100;
  position: absolute;
}

.dropdown-options {
  position: absolute;
  top: calc(100% + 5px);
  right: 0;
  width: 100%;
  background-color: #eeebdd;
  border-radius: 5px;
  flex-direction: column;
  max-height: 300px;
  overflow-y: scroll;
  z-index: 11;
}

.dropdown-options::-webkit-scrollbar {
  width: 10px;
}

.dropdown-options::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}

.dropdown-options::-webkit-scrollbar-thumb {
  background: #aaa;
  border-radius: 10px;
}

.dropdown-options::-webkit-scrollbar-thumb:hover {
  background: #666;
}

.dropdown-option {
  padding: 10px 10px 10px 20px;
  width: 100%;
}

.dropdown-option:hover {
  background-color: #ccc;
}

.multiple-select-label {
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 10px;
  gap: 5px;
}

.multiple-select-option {
  display: flex;
  justify-content: space-between;
}

.multiple-select-option span {
  margin-right: 5px;
}

.not-selected {
  display: none;
}

.selected {
  display: unset;
}

.fa-circle-check {
  color: #ce1212;
}

.inline-icon {
  position: absolute;
  width: 15px;
  height: 15px;
  top: calc(50% - 7.5px);
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inline-icon:hover {
  cursor: pointer;
}

.clear-all:hover i {
  color: red;
}

.group-title {
  font-size: 16px;
  font-weight: bold;
  padding: 10px;
}

.genres-label {
  height: 100%;
  position: relative;
}

.genres-label .placeholder {
  top: calc(50% - 7.5px);
  left: 10px;
}

.selected-label {
  display: flex;
  gap: 5px;
  height: 100%;
  position: absolute;
  align-items: center;
  top: 0;
  left: 0;
  padding-left: 10px;
}

.multiple-select-span {
  border: 1px solid #ccc;
  background-color: #ccc;
  padding: 3px;
  border-radius: 5px;
  height: fit-content;
}

.filter-input {
  width: 100%;
  height: 100%;
  padding: 5px 5px 5px 10px;
  background-color: #eeebdd;
  border: none;
  border-radius: 5px;
  outline: none;
}

.menu-item-label {
  height: 100%;
  position: relative;
}

.menu-item-label .selected-value {
  position: absolute;
  font-weight: bold;
  color: rgb(0, 128, 255);
  top: calc(50% - 7.5px);
  left: 10px;
}
.menu-item-label .placeholder {
  color: #1b1717;
  font-weight: 100;
}

@media all and (max-width: 1000px) {
  .search-menu {
    display: grid;
  }

  .menu-item {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
