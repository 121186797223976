.confirm-window-overlay {
  width: 100%;
  height: 100%;
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  cursor: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.delete-account-confirm-window {
  display: flex;
  flex-direction: column;
  gap: 15px;
  background-color: #fff;
  padding: 20px;
  border: 5px solid #222;
}

.delete-account-confirm-window p {
  font-size: 1.3rem;
}

.delete-account-confirm-window p span {
  color: red;
  font-size: 1.2rem;
}
.delete-account-confirm-window .btn-group {
  display: flex;
  justify-content: space-around;
}

.delete-account-confirm-window .btn-group button:hover {
  cursor: pointer;
}

.delete-account-confirm-window .btn-group button {
  padding: 2px 10px;
  background-color: rgb(21, 12, 77);
  color: #eeebdd;
  display: block;
  cursor: pointer;
  border: none;
}
