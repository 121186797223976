.profile-side-menu {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  background-color: #ce1212;
  margin-left: 10px;
  position: fixed;
  z-index: 99;
  transition: opacity 0.3s ease-in;
  opacity: 0.3;
  animation: comeFromLeft 0.5s linear;
}

@keyframes comeFromLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

.side-menu-item {
  padding: 1rem;
  font-size: 2rem;
  color: #eeebdd;
  transition: all 0.2s ease;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
}

.profile-side-menu:hover {
  opacity: 1;
}

.side-menu-item:hover {
  background-color: #810000;
  cursor: pointer;
}

.side-menu-edit:hover::after,
.side-menu-list:hover::after {
  font-size: 1rem;
  position: absolute;
  content: attr(data-type);
  padding: 5px;
  white-space: nowrap;
  background-color: #fff;
  color: black;
  left: 100%;
  pointer-events: none;
}
