.mobile-list-wrapper {
  padding: 5px;
  min-height: 100%;
  overflow: hidden;
}

.mobile-list-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
}
