.profile-stats {
  display: grid;
  grid-template-columns: 1fr 3fr;
}

.stats-data {
  font-size: 1.3rem;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 10px;
}

.watching .right {
  color: rgba(0, 0, 255, 0.7);
}
.completed .right {
  color: rgba(8, 205, 8, 0.7);
}
.dropped .right {
  color: rgba(255, 0, 0, 0.7);
}
.on-hold .right {
  color: rgba(255, 165, 0, 0.7);
}
.plan-to-watch .right {
  color: rgba(128, 128, 128, 0.7);
}

.stats-data p {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  gap: 3px;
}

.stats-data p span {
  color: black;
}

.stats-data p .mid {
  flex-grow: 1;
  border-bottom: 2px dotted black;
}

.chart {
  justify-self: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.chart-container {
  width: 70%;
  height: 100%;
}

.chart .chart-icon {
  text-align: right;
  width: 100%;
  position: absolute;
}

.chart .chart-icon i {
  margin-right: 55px;
}

.chart .chart-icon i:hover {
  cursor: pointer;
  color: white;
}

@media all and (max-width: 1000px) {
  .profile-stats {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  .chart .chart-icon i {
    margin-right: 5px;
  }
}

@media all and (max-width: 500px) {
  .chart-container {
    width: 90%;
  }
}
