.advanced-search-result {
  display: grid;
  background-color: #222;
  width: 100%;
  margin: auto;
  align-items: center;
}

.grid-without-rank {
  grid-template-columns: min-content 3fr 0.8fr 0.8fr 1fr;
}

.grid-with-rank {
  grid-template-columns: 0.2fr min-content 2fr 0.5fr 0.5fr 0.7fr;
}

.search-res-rank {
  color: #eeebdd;
  font-size: 35px;
  font-weight: bold;
  text-align: center;
}

.cover-image {
  width: 70px;
  height: 90px;
}

.cover-image img {
  width: 100%;
  height: 100%;
}

.advanced-search-result .adv-search-res-item {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  min-width: 0;
}

.advanced-search-result .res-item-heading {
  color: #eeebdd;
  font-size: 15px;
  font-weight: bold;
}

.advanced-search-result .res-item-details {
  color: #a1a1a1;
  font-size: 13px;
}

.advanced-search-result .title-genres .genres {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  overflow: hidden;
  height: 20px;
}

.genre {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: gold;
  border-radius: 10px;
  padding: 3px 8px;
  font-weight: bold;
  color: #1b1717;
}

.genre:hover {
  cursor: pointer;
  color: blue;
}

.title-link {
  width: 100%;
}

.title-link:hover {
  text-shadow: -0.5px -0.5px 0 #ce1212, 0.5px -0.5px 0 #ce1212,
    -0.5px 0.5px 0 #ce1212, 0.5px 0.5px 0 #ce1212;
}
