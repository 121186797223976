.trailer-container {
  width: 100%;
  text-align: center;
}

.trailer-container h2 {
  font-size: 2.5rem;
  color: #eeebdd;
  margin-bottom: 1rem;
}

.trailer-container iframe {
  width: 560px;
  height: 315px;
}

@media all and (max-width: 600px) {
  .trailer-container iframe {
    width: 460px;
    height: 215px;
  }
}
@media all and (max-width: 500px) {
  .trailer-container iframe {
    width: 360px;
    height: 215px;
  }
}

@media all and (max-width: 400px) {
  .trailer-container iframe {
    width: 260px;
    height: 215px;
  }
}
@media all and (max-width: 300px) {
  .trailer-container iframe {
    width: 160px;
    height: 215px;
  }
}
