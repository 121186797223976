.list-page {
  min-height: 100%;
}

.mylist-container {
  display: flex;
  flex-direction: column;
  border: 2px solid #ce1212;
  width: 80%;
  margin: auto;
}

.viewed-status {
  color: white;
  text-align: center;
  margin-bottom: 45px;
}

.mylist-container:last-child {
  border-bottom: none;
}

#list-header {
  border: none;
  font-size: 1.5rem;
  font-weight: bold;
  text-decoration: underline;
  text-underline-position: under;
  padding-top: 2rem;
  padding-bottom: 3rem;
  background-color: #222;
}

#list-header .mylist-item-title {
  width: 100%;
  text-align: center;
  padding: 0
}

.mobile-list-header {
  display: none;
  border: none;
  font-size: 2rem;
  font-weight: bold;
  padding-bottom: 1rem;
  text-decoration: underline;
  text-underline-position: under;
  color: #eeebdd;
  text-align: center;
}

.sort {
  cursor: pointer;
}

.sort:hover {
  text-shadow: 0 0 3px white, 0 0 5px #ce1212;
}
