.characters-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  grid-gap: 10px;
}

.va-lang-dropdown {
  position: absolute;
  top: 0;
  right: 0;
  height: 30px;
  border: none;
  outline: none;
  border-radius: 5px;
  width: 150px;
  background-color: #eeebdd;
  font-size: 1.5rem;
}

.va-lang-dropdown:hover {
  cursor: pointer;
}

.va-lang-dropdown span {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-left: 5px;
}
.lang-dropdown-options {
  position: absolute;
  top: calc(100% + 5px);
  right: 0;
  width: 100%;
  background-color: #eeebdd;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}

.lang-dropdown-option {
  padding: 5px;
}

.lang-dropdown-option:hover {
  background-color: #ccc;
}

@media screen and (max-width: 850px) {
  .characters-container {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 350px) {
  .va-lang-dropdown {
    position: relative;
    margin-bottom: 10px;
    width: 100%;
  }
}
