.mobile-edit-item-overlay {
  width: 100%;
  height: 100%;
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  cursor: auto;
}

.mobile-item-edit-window-container {
  width: 100%;
  height: 300px;
  border: 5px solid #ce1212;
  position: absolute;
  left: 0;
  background-color: #eeebdd;
  overflow-y: scroll;
}

.mobile-item-edit-form {
  width: 100%;
  height: 100%;
  color: #1b1717;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.mobile-item-edit-form h1 {
  font-size: 1.7rem;
  margin-bottom: 10px;
}

.mobile-item-edit-input-group {
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 5px;
  font-size: 1.3rem;
}

.mobile-item-edit-input-group label {
  width: 80px;
  font-weight: bold;
}

.mobile-item-edit-input-group select {
  width: 90%;
  height: 100%;
  border-radius: 5px;
  border: 2px solid #f27878;
  min-height: 30px;
}

.mobile-item-edit-input-group input {
  min-height: 30px;
}

.mobile-item-edit-input-group select:focus {
  outline: none;
}

.mobile-item-edit-input-group select:hover {
  cursor: pointer;
}

.mobile-item-edit-progress-input,
.mobile-item-edit-comment-input {
  position: relative;
  width: 90%;
  height: 100%;
  border-radius: 5px;
}

.mobile-item-edit-progress-input input,
.mobile-item-edit-comment-input textarea {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  border: 2px solid #f27878;
  padding: 5px;
}

.mobile-item-edit-progress-input input:focus,
.mobile-item-edit-comment-input textarea:focus {
  outline: none;
}

.mobile-item-edit-progress-input:after {
  position: absolute;
  content: "/" attr(data-max);
  right: 20px;
  top: 7.5px;
  font-size: 1.3rem;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;
}

.mobile-item-edit-form-buttons {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  gap: 10px;
  margin-top: 15px;
  padding-bottom: 10px;
}

.mobile-item-edit-form-buttons button {
  font-size: 1.5rem;
  width: 100px;
  height: 40px;
}
