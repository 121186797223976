.spinner-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.spinner {
  animation: spin 1s linear infinite;
}

.inline-spinner-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner img {
  width: 100% !important;
  height: 100% !important;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
