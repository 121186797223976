.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ce1212;
}

.navbar-left {
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar-right {
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar .nav-item {
  font-size: 1.5rem;
  padding: 1rem;
  color: #eeebdd;
  transition: background-color 0.2s ease;
}

.navbar .nav-item:hover {
  background-color: #810000;
  border-radius: 5px;
  cursor: pointer;
}

.notification-bell {
  position: relative;
}

.notification-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 1px;
  left: 1px;
  background-color: red;
  min-width: 14px;
  height: 14px;
  border-radius: 25px;
  font-size: 10px;
  padding: 0 2px;
  color: #eeebdd;
}

.user-menu {
  position: relative;
}

.avatar-wrapper {
  padding-right: 5px;
  display: flex;
  align-items: center;
  gap: 3px;
  padding: 0.55rem;
}

.user-menu .avatar-wrapper:hover {
  background-color: #810000;
  border-radius: 5px;
  cursor: pointer;
}

.user-menu .avatar-wrapper img {
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

.drop-down-arrow {
  border: 4px solid #fff;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  display: inline-block;
  height: 0;
  width: 0;
  margin-top: 4px;
}
