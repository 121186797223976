.review-info {
  display: flex;
  gap: 10px;
}

.review-details {
  display: flex;
  gap: 10px;
  color: #eeebdd;
  font-size: 1.2rem;
  justify-content: space-between;
  width: 100%;
}

.review-details__left-side,
.review-details__right-side {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.review-details__right-side {
  text-align: right;
}

.user-avatar img {
  width: 50px;
  height: 60px;
}

.review {
  width: 100%;
}

.review-content {
  max-width: 100%;
  padding: 20px 0;
}

.review-content p {
  max-width: 100%;
  overflow-wrap: break-word;
}

.author {
  display: flex;
  align-items: flex-end;
  gap: 10px;
}

.author a {
  color: rgb(0, 128, 255);
}

.author a:hover {
  text-decoration: underline;
}

.author p:nth-child(2) {
  font-size: 10px;
  letter-spacing: 0.5px;
}

.review-content {
  color: #eeebdd;
  font-size: 1.3rem;
  letter-spacing: 0.5px;
  line-height: 18px;
}

.review-overflow-hidden {
  height: 200px;
  overflow: hidden;
}

.review-content-button {
  color: rgb(0, 128, 255);
  cursor: pointer;
}
