.staff-page {
  width: 100%;
  min-height: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.staff-content {
  display: flex;
  flex-direction: column;
  width: 95%;
  margin: auto;
  gap: 40px;
}
