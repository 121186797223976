.must-be-logged {
  width: 300px;
  height: 200px;
  border: 5px solid #ce1212;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  position: fixed;
  top: calc(50% - 100px);
  left: calc(50% - 150px);
  color: #ce1212;
  font-size: 3rem;
  font-weight: bold;
  background-color: #eeebdd;
  z-index: 1000;
}

@media all and (max-width: 850px) {
  .must-be-logged {
    transform: translateX(calc(50% - 150px));
    transform: translateY(calc(50% - 100px));
  }
}
