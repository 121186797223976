.entry-information {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  background-color: #0e0d0d;
  width: 225px;
  border-radius: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.entry-information h3 {
  color: #eeebdd;
  padding: 1rem;
}

.info-item {
  display: flex;
  flex-direction: column;
  gap: 3px;
  color: #eeebdd;
  font-size: 1.3rem;
  width: 100%;
  padding: 0.5rem 1.5rem;
}

.content {
  font-size: 1.2rem;
  font-style: italic;
  color: #a1a1a1;
}
