.profile-heading {
  text-align: center;
  font-size: 5rem;
  padding: 4rem;
  background-image: linear-gradient(
    45deg,
    rgba(255, 0, 0, 1) 0%,
    rgba(255, 154, 0, 1) 10%,
    rgba(208, 222, 33, 1) 20%,
    rgba(79, 220, 74, 1) 30%,
    rgba(63, 218, 216, 1) 40%,
    rgba(47, 201, 226, 1) 50%,
    rgba(28, 127, 238, 1) 60%,
    rgba(95, 21, 242, 1) 70%,
    rgba(186, 12, 248, 1) 80%,
    rgba(251, 7, 217, 1) 90%,
    rgba(255, 0, 0, 1) 100%
  );
  background-clip: text;
  color: transparent;
  text-align: center;
}

.main-profile-content {
  width: 90%;
  min-height: 100vh;
  background-color: #eeebdd;
  margin: auto;
  display: flex;
}

.main-profile-content__right-side {
  flex-grow: 1;
}

.profile-content {
  padding: 1rem;
  display: flex;
  flex-direction: column;
}

.about-me {
  width: 100%;
  padding: 1rem;
  font-size: 1.2rem;
}

.statistics {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.user-info {
  padding: 1rem;
  border: 1px solid #ccc;
  border-bottom: none;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
}

/* slide css */

.fav-list .carouselForwardArrow,
.fav-list .carouselBackArrow {
  opacity: 0;
  transition: 0.3s all ease;
  position: absolute;
  z-index: 2;
  background-color: rgba(199, 199, 199, 0.593);
}
.fav-list .carouselForwardArrow {
  right: 0;
}

.fav-list .carouselBackArrow {
  left: 0;
}

.fav-list .carouselForwardArrow:hover,
.fav-list .carouselBackArrow:hover {
  background-color: rgb(199, 199, 199);
}
.fav-list:hover .carouselForwardArrow,
.fav-list:hover .carouselBackArrow {
  opacity: 1;
}

/* slide css */
