* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  width: 100%;
  height: 100%;
  font-size: 10px;
  font-family: "Questrial", sans-serif;
  scroll-behavior: smooth;
}

body {
  width: 100%;
  height: 100%;
  background-color: #1b1717;
  overflow-y: scroll;
}

#root {
  width: 100%;
  height: 100%;
  position: relative;
}

a {
  color: inherit;
  text-decoration: none;
}

.input-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 5px;
}

.input-group input {
  padding: 1rem;
}

.btn {
  color: inherit;
  font-size: 1.5rem;
  padding: 0.75rem 1.5rem;
  border-radius: 35px;
  box-shadow: 1px 1px 1px #000;
  transition: all 0.1s linear;
  border: none;
}

.btn:hover {
  cursor: pointer;
}

.btn:active {
  box-shadow: 1px 1px 1px #000 inset;
}

.cool-btn {
  background-color: #ce1212;
  color: #1b1717;
  border: 2px solid #1b1717;
  font-weight: bold;
  box-shadow: 1px 1px 1px #ce1212;
  transition: all 0.1s linear;
  border-radius: 5px;
}

.cool-btn:active {
  box-shadow: 1px 1px 1px #000 inset;
}

.cool-btn:hover {
  cursor: pointer;
}

.flex {
  display: flex;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
