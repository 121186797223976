.info-window {
    z-index: 100000000;
    width: 280px;
    background-color: #eeebdd;
    border-radius: 10px;
    padding: 10px;
    opacity: 0.95;
    display: flex;
    flex-direction: column;
    gap: 5px;
    position: absolute;
    border: 2px solid #ce1212
}

.info-window-left::after {
    width: 0;
    height: 0;
    content: "";
    z-index: 2;
    transform: scale(1.01);
    border-right: 8px solid #ce1212;
    border-bottom: 6px solid transparent;
    border-top: 6px solid transparent;
    right: 100%;
    position:absolute;
    top: 15px;
}

.info-window-right::after {
    width: 0;
    height: 0;
    content: "";
    z-index: 2;
    transform: scale(1.01);
    border-left: 8px solid #ce1212;
    border-bottom: 6px solid transparent;
    border-top: 6px solid transparent;
    left: 100%;
    position:absolute;
    top: 15px;
}


.info-window-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.info-window-title {
    font-size: 16px;
    font-weight: bold;

}

.info-window-score {
    font-size: 15px;
    font-weight: 500;

}

.info-window-score .fa-star {
    color: rgb(255, 217, 0)
}

.info-window-format {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ce1212;
    border-radius: 10px;
    padding: 3px 8px;
    font-weight: bold;
    color: #fff;
}

.info-window-desc {
    height: 75px;
    color: black;
    border-radius: 5px;
    width: fit-content;
    text-align: left;
    white-space: pre-line;
    word-break: break-word;
    overflow: hidden;
    overflow-y: scroll;
}

.info-window-desc::-webkit-scrollbar {
    width: 5px;
  }
  
  .info-window-desc::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 10px;
  }
  
  .info-window-desc::-webkit-scrollbar-thumb {
    background: #aaa;
    border-radius: 10px;
  }
  
  .info-window-desc::-webkit-scrollbar-thumb:hover {
    background: #666;
  }
  

.info-window-release {
    font-size: 13px;    
    font-weight: bold;  
    color:rgb(0, 128, 255);
}

.info-window-genres {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    overflow: hidden;
    height: 20px;
}

.info-window-genres .genre {
    background-color: #ce1212;
    color: #fff;
}