.review-textarea {
  width: 100%;
  height: 200px;
  resize: none;
  padding: 5px;
}

.review-button {
  padding: 2px 10px;
  background-color: rgb(21, 12, 77);
  color: #eeebdd;
  margin: 10px 0 30px;
  margin-left: auto;
  display: block;
  cursor: pointer;
  border: none;
}

.review-stats {
  display: flex;
  gap: 10px;
  font-size: 1.5rem;
  color: #eeebdd;
  margin-bottom: 10px;
  align-items: flex-end;
}

.review-stats-field {
  background-color: #1b1717;
  color: #eeebdd;
  font-size: 1.5rem;
  font-family: "Questrial", sans-serif;
  border: none;
  outline: none;
  border-left: 1px solid #eeebdd;
  padding-left: 5px;
  cursor: pointer;
}

.watched-episodes input {
  width: 30px;
}

.review-error-message {
  color: red;
  font-size: 1.4rem;
  margin-bottom: 10px;
}

/* Chrome, Safari, Edge, Opera */
.watched-episodes input::-webkit-outer-spin-button,
.watched-episodes input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.watched-episodes input[type="number"] {
  -moz-appearance: textfield;
}
