.editprofile-page {
  width: 100%;
  height: 100%;
}

.edit-profile-content {
  width: 90%;
  min-height: 100vh;
  background-color: #eeebdd;
  margin: auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.edit-profile-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
/* form:nth-child(even) {
  background-color: #ce1212;
} */

.edit-input {
  display: flex;
  align-items: center;
}

.edit-input label {
  min-width: 100px;
}

@media all and (max-width: 550px) {
  .edit-field {
    align-items: flex-end;
  }
  .edit-input {
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
  }
}
