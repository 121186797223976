.profile-page {
  width: 100%;
  min-height: 100vh;
}

.profile-page h2 {
  font-size: 2rem;
}

.profile-page h3 {
  font-size: 1.5rem;
  text-decoration: underline;
  text-underline-position: under;
}
