.character-page {
  width: 100%;
  min-height: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.display-linebreak {
  white-space: pre-line;
}

.character-content {
  display: flex;
  flex-direction: column;
  width: 95%;
  margin: auto;
  gap: 40px;
}

.character-page h2 {
  color: #eeebdd;
  font-size: 2rem;
  margin-bottom: 25px;
}
