.drop-down-menu {
  position: absolute;
  right: 0;
  background-color: #810000;
  display: flex;
  flex-direction: column;
  z-index: 99;
}

.dropdown-item {
  font-size: 1.5rem;
  padding: 1rem;
  color: #eeebdd;
  transition: all 0.2s ease;
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 10px;
}

.dropdown-item:hover {
  color: #810000;
  background-color: #eeebdd;
  cursor: pointer;
}

.logged-user {
  border-bottom: 1px solid #eeebdd;
}

.dropdown-icon-wrapper {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
