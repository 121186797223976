.friend-req-page {
  min-height: 100vh;
  width: 85%;
  margin: auto;
  background: #eeebdd;
}

.friend-req-page h1 {
  margin: 10px 0;
}

.old-friend-req-container,
.new-friend-req-container {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: auto;
}

.old-friend-req-container hr {
  margin: 5px 0;
}

@media all and (max-width: 500px) {
  .old-friend-req-container,
  .new-friend-req-container {
    width: 100%;
    margin: 0;
  }
}
