.mobile-main-profile-content {
  width: 90%;
  min-height: 100vh;
  background-color: #eeebdd;
  margin: auto;
  padding: 10px;
}

.avatar-user-function {
  display: flex;
  gap: 15px;
  align-items: flex-end;
  height: auto;
}

.mobile-profile-page hr {
  margin-top: 20px;
  margin-bottom: 20px;
}
