.login-window {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
  position: fixed;
}

.login-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  background-color: #eeebdd;
  border: 5px solid #ce1212;
  padding: 2rem;
  color: #1b1717;
  font-size: 1.5rem;
}

.login-form h1 {
  font-size: 3rem;
  letter-spacing: 1px;
}

.login-form .btn {
  width: 100%;
}

.login-form a {
  border-bottom: 2px solid #1b1717;
}

.wrong-info-alert {
  font-size: 1rem;
  color: red;
}
