.search-result-item {
  display: flex;
  gap: 10px;
  color: white;
  width: 100%;
  padding: 10px;
  margin: auto;
}

.search-item-list {
  display: flex;
  justify-content: flex-start;
  gap: 5px;
}
.list-container {
  display: flex;
  gap: 2px;
  flex-wrap: wrap;
}

.search-result-item .image img {
  width: 50px;
  height: 80px;
}

.search-result-item:hover {
  background-color: #810000;
  cursor: pointer;
}

.search-item-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
}

.search-result-item h3 {
  font-weight: bold;
  font-size: 1.2rem;
}
.underline {
  text-decoration: underline;
}
