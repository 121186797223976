.user-function {
  display: flex;
  align-items: center;
  gap: 5px;
}

.friend-request {
  position: relative;
  transition: background-color 0.3s ease;
  border-radius: 5px;
}

.friend-request img {
  width: 50px;
  height: 50px;
}

.disabled {
  border: 1px solid #c3c3c3;
}

.abled {
  border: 1px solid #ce1212;
}

.abled:hover {
  cursor: pointer;
  background-color: antiquewhite;
}

.abled:hover:after,
.req-sent:hover:after {
  font-size: 1rem;
  position: absolute;
  content: attr(data-type);
  padding: 5px;
  white-space: nowrap;
  background-color: #fff;
  color: black;
  pointer-events: none;
  top: 100%;
  left: 0;
}
