.landing-page-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
}

.landing-page-section h1 {
  color: #eeebdd;
  font-size: 2.5rem;
  border-left-color: #ce1212;
  border-left: 1px;
}

.heading {
  display: flex;
  gap: 10px;
}

.heading div {
  width: 5px;
  background-color: #ce1212;
}

.view-all {
  position: absolute;
  font-size: 14px;
  color: rgb(0, 128, 255);
  right: 11px;
  top: 5px;
}

.view-all:hover {
  font-weight: bold;
  cursor: pointer;
  color: rgb(0, 128, 255);
}

@media all and (max-width: 400px) {
  .view-all {
    top: 0;
  }

  .landing-page-section h1 {
    font-size: 2rem;
  }
}
@media all and (max-width: 350px) {
  .landing-page-section h1 {
    font-size: 1.5rem;
  }
}
