.character-banner {
  width: 100%;
  height: 400px;
}

@media all and (max-width: 800px) {
  .character-banner {
    width: 100%;
    height: 300px;
  }
}

@media all and (max-width: 600px) {
  .character-banner {
    width: 100%;
    height: 200px;
  }
}
